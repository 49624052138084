import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiCodepen} from 'react-icons/fi'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/mat%C3%A9o-delforge-12a4581b8/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/Frosterian" target="_blank"><FaGithub/></a>
        <a href="https://codepen.io/frosterian" target="_blank"><FiCodepen/></a>
    </div>
  )
}

export default HeaderSocials