import React from 'react'
import './footer.css'
import {AiOutlineFacebook} from 'react-icons/ai'
import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'




const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Matéo Delforge</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className='footer__socials'>
        <a href="https://www.facebook.com/mateo.delforge.7/"><AiOutlineFacebook className='footer__socials-icon'/></a>
        <a href="https://www.instagram.com/mateodelforge/"><AiOutlineInstagram className='footer__socials-icon'/></a>
        <a href="https://www.linkedin.com/in/mat%C3%A9o-delforge-12a4581b8/"><AiOutlineLinkedin className='footer__socials-icon'/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Mateo Delforge, Tous droits réservés.</small>
      </div>
    </footer>
  )
}

export default Footer