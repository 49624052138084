import React from 'react'
import './about.css'
import ME from '../../assets/me1.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>À savoir</h5>
      <h2>À propos de moi</h2>
      <div className="container about__container">
        <div className="about__me">
            <div className="about__me-image">
              <img src={ME} alt="About image" />  
            </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>3 ans en Freelance</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>Plus de 10 Clients</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projets</h5>
              <small>20+ Finis</small>
            </article>
          </div>
            <p>Actuellement en deuxième année d’expertise digitale chez WIS Lille, je suis à la recherche d’une alternance pour la rentrée prochaine (septembre 2022), avec possibilité de démarrer dès juillet. Je dispose d’un bon relationnel, d’une grande capacité d’écoute et d’analyse, ainsi que de solides connaissances en programmation et langages informatiques. Permis B, véhiculé.</p>
            <a href="#contact" className='btn btn-primary'>Discutons !</a>
        </div>
      </div>
    </section>
  )
}

export default About