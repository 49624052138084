import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h5>Qu'est-ce que je peux offrir ?</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de wireframe / zoning</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de charte graphique</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de logo</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de maquette graphique</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Refonte de site web et d'application</p>
            </li>
          </ul>
        </article>     

        <article className="service">
          <div className="service__head">
            <h3>Web developpement</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de site vitrine / e-commerce avec Wordpress</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de site e-commerce avec Prestashop</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de site web / applications web avec NodeJS (React, Angular, VueJS)</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de site web classique (HTML, CSS, Javascript)</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Intégration d'animation (CSS, Javascript) </p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création d'application web dynamique (HTML, CSS, PHP, Javascript)</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Analyse SEO</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Création de contenu</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Animation des réseaux sociaux</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de Post photo (Photoshop)</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création d'affiche</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Création de bannière</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Analyse des réseaux</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Jeux concours originaux / événements</p>
            </li>
          </ul>
        </article>

      </div>
    </section>
  )
}

export default Services