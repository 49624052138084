import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'AsselinMigani',
    github: 'https://asselinmigani.fr',
    demo: 'https://asselinmigani.fr',
    description: 'Création d\'un site web dynamique avec contact, (asselinmigani.fr), création de leurs réseaux sociaux (Instagram, Twitter, Pinterest) avec plan de communication et création d\'une affiche publicitaire affichée dans tout le Douaisie.'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Animations open source',
    github: 'https://codepen.io/frosterian',
    demo: 'https://codepen.io/frosterian',
    description: 'Création de différentes animations sur Codepen pour me divertir, et les rendre accessibles à tous.'
  },
  // {
  //   id: 3,
  //   image: IMG3,
  //   title: 'Affichage dynamique en JS',
  //   github: 'https://github.com',
  //   demo: 'https://asselinmigani.fr',
  //   description: 'Pour mon école, '
  // },
  // {
  //   id: 4,
  //   image: IMG4,
  //   title: 'Crypto dashboard',
  //   github: 'https://github.com',
  //   demo: 'https://asselinmigani.fr',
  //   description: 'Ce site à été conçu dans le but de...'
  // },
  // {
  //   id: 5,
  //   image: IMG5,
  //   title: 'Crypto dashboard',
  //   github: 'https://github.com',
  //   demo: 'https://asselinmigani.fr',
  //   description: 'Ce site à été conçu dans le but de...'
  // },
  // {
  //   id: 6,
  //   image: IMG6,
  //   title: 'Crypto dashboard',
  //   github: 'https://github.com',
  //   demo: 'https://asselinmigani.fr',
  //   description: 'Ce site à été conçu dans le but de...'
  // }
]

const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Mes travaux</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo, description}) => {
            return(
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <p>{description}</p>
              <div className='portfolio__item-cta'>
              <a href={github} className='btn' target='_blank'>Code source</a>
              <a href={demo} className='btn btn-primary' target='_blank'>Lien vers le site</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default portfolio