import React from 'react'
import './experience.css'
import {HiBadgeCheck} from 'react-icons/hi'
const Experience = () => {
  return (
    <section id='experience'>
      <h5>Quelles sont mes compétences ?</h5>
      <h2>Mes Compétences</h2>

      <div className="container experience__container">
        <div className="experience_frontend">
          <h3>Frontend Developpement</h3>
          <div className="experience__content">
            
          <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
                <h4>HTLM</h4>
                <small>Avancé</small>
              </div>
            </article>   
            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
                <h4>CSS</h4>
                <small>Avancé</small>
              </div>
            </article>  
            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
                <h4>Javascript</h4>
                <small>Avancé</small>
              </div>
            </article>  
            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Bootstrap</h4>
              <small>Avancé</small>
              </div>
            </article>   
            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Tailwind</h4>
              <small>Medium</small>
              </div>
            </article>  
            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Typescript</h4>
              <small>Medium</small>
              </div>
            </article>

          </div>
        </div>
        <div className="experience__backend">
        <h3>Backend Developpement</h3>
          <div className="experience__content">
            
          <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>NodeJS</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>MongoDB</h4>
              <small>Medium</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>PHP</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>MySQL</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Python</h4>
              <small>Medium</small>
              </div>
            </article>            
          </div>
        </div>

        <div className="experience__backend">
        <h3>UI / UX Developpement</h3>
          <div className="experience__content">
            
          <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Figma</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Illustrator</h4>
              <small>Medium</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Photoshop</h4>
              <small>Medium</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>SEO</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Adobe XD</h4>
              <small>Medium</small>
              </div>
            </article>            
          </div>
        </div>
        <div className="experience__backend">
        <h3>Chef de Projet</h3>
          <div className="experience__content">
            
          <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Xmind</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Project Libre</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Pack Office</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Power BI</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Wrike</h4>
              <small>Avancé</small>
              </div>
            </article>            
            <article className="experience__details">
              <HiBadgeCheck className="experience__details-icon"/>
              <div>
              <h4>Trello</h4>
              <small>Avancé</small>
              </div>
            </article>            
          </div>
        </div>
        </div>
    </section>
  )
}

export default Experience