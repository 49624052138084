import React from 'react'
import './contact.css'
import {AiOutlineMail} from 'react-icons/ai'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9xqbfaf', 'template_k8no5aj', form.current, 'iDLysu__RkwDa1r02')

    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Envie de me contacter ?</h5>
      <h2>Contactez - moi</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <AiOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>delforge.mateo@gmail.com</h5>
            <a href="mailto:delforge.mateo@gmail.com">Envoyer un message</a>
          </article>

          
          <article className='contact__option'>
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Matéo Delforge</h5>
            <a href="https://m.me/mateo.delforge.7" target='_blank'>Envoyer un message</a>
          </article>

          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>0787249322</h5>
            <a href="https://api.whatsapp.com/send?phone=+33787249322" target='_blank'>Envoyer un message</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail} action="">
          <input type="text" name='name' placeholder='Nom Prenom' required />
          <input type="email" name='email' placeholder='adresse mail' required />
          <input type="text" name='subject' placeholder='Sujet' required />
          <textarea name="message" rows="7" placeholder='Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Envoyer</button>
        </form>
      </div>
    </section>
  )
}

export default Contact